import { createSlice } from '@reduxjs/toolkit';

const initialState = {
   menuOpen: false,
   dropdownOpen: { id: '', status: false },
};

export const globalSlice = createSlice({
   name: 'global',
   initialState,
   reducers: {
      openMenu: (state) => {
         state.menuOpen = true;
      },
      closeMenu: (state) => {
         state.menuOpen = false;
         state.dropdownOpen = { id: '', status: false };
      },
      toggleMenu: (state) => {
         state.menuOpen = !state.menuOpen;
         state.dropdownOpen = { id: '', status: false };
      },
      openDropdown: (state, action) => {
         state.dropdownOpen = {
            id: action.payload,
            status: true,
         };
      },
      closeDropdown: (state) => {
         state.dropdownOpen = { id: '', status: false };
      },
      toggleDropdown: (state, action) => {
         if (state.dropdownOpen.status) {
            state.dropdownOpen = { id: '', status: false };
         } else {
            state.dropdownOpen = { id: action.payload, status: true };
         }
      },
   },
});

// Action creators are generated for each case reducer function
export const {
   openMenu,
   closeMenu,
   toggleMenu,
   openDropdown,
   closeDropdown,
   toggleDropdown,
} = globalSlice.actions;

export const selectMenuOpen = (state) => state.global.menuOpen;
export const selectdropdownOpen = (state) => state.global.dropdownOpen;

export default globalSlice.reducer;
