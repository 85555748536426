import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { breakpoints, dimensions } from '../../styles/globalStyle';

const Hero = ({ heroTitle, heroHeading, heroImage }) => {
   return (
      <HeroWrap>
         <GatsbyImage
            image={heroImage.gatsbyImageData}
            alt='hero image'
            aspectRatio={2 / 1}
            layout='fullWidth'
            formats={['auto', 'webp', 'avif']}
         />

         {heroTitle && (
            <HeroContent className='container'>
               <div className='inner-content'>
                  <h1>
                     {heroTitle.map((item) => (
                        <span key={item.id}>{item.listItem}</span>
                     ))}
                  </h1>
               </div>
            </HeroContent>
         )}
      </HeroWrap>
   );
};

export default Hero;

const HeroWrap = styled.div`
   display: grid;
   height: 500px;

   .gatsby-image-wrapper {
      position: relative;
      grid-area: 1 / 1;

      &::after {
         content: '';
         position: absolute;
         top: 0;
         left: 0;
         height: 100%;
         width: 100%;
         background: linear-gradient(
            to right,
            rgba(0, 0, 0, 0.85) 50%,
            rgba(0, 0, 0, 0.3)
         );
      }
   }

   @media screen and (min-width: ${breakpoints.desktop}px) {
      /* height: unset; */
      min-height: 100vh;

      .gatsby-image-wrapper {
         &::after {
            background: linear-gradient(
               to right,
               rgba(0, 0, 0, 0.9) 45%,
               rgba(0, 0, 0, 0)
            );
         }
      }
   }
`;

const HeroContent = styled.div`
   position: relative;
   display: grid;
   grid-area: 1 / 1;
   align-items: center;
   /* padding-bottom: 2rem; */
   height: 100%;

   .inner-content {
      max-width: 80%;

      h1 {
         display: flex;
         flex-direction: column;
         gap: 0.5rem;
         color: ${(props) => props.theme.colors.white};
         font-size: 2.75rem;
         padding-top: ${dimensions.headerHeight};
         text-transform: capitalize;
         font-weight: 700;
         font-family: ${(props) => props.theme.fonts.hero};
      }

      p {
         background-color: ${(props) => props.theme.colors.white};
         color: ${(props) => props.theme.colors.gray};
         padding: 0.5rem 1rem;
      }
   }

   @media screen and (min-width: ${breakpoints.tablet}px) {
      .inner-content {
         max-width: 70%;

         h1 {
            font-size: 2.5rem;
            text-align: left;
         }
      }
      @media screen and (min-width: ${breakpoints.desktop}px) {
         .inner-content {
            h1 {
               font-size: 5rem;
               line-height: 95px;
            }

            p {
               font-size: 1.5rem;
            }
         }
      }
   }
`;
