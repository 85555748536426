import * as React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function Seo({ description, lang, meta, title, image: metaImage, pathname }) {
   const { site, cardImg } = useStaticQuery(
      graphql`
         query {
            site {
               siteMetadata {
                  siteUrl
                  title
                  description
                  author
                  keywords
               }
            }
            cardImg: file(name: { eq: "image-card" }) {
               childImageSharp {
                  original {
                     width
                     src
                     height
                  }
               }
            }
         }
      `
   );

   console.log('cardImg', cardImg.publicURL);
   console.log('site', site.siteMetadata.siteUrl);

   const metaDescription = description || site.siteMetadata.description;
   const image =
      metaImage && metaImage.src
         ? `${site.siteMetadata.siteUrl}${metaImage.src}`
         : null;
   const canonical = pathname
      ? `${site.siteMetadata.siteUrl}${pathname}`
      : null;

   const defaultTitle = site.siteMetadata?.title;

   return (
      <Helmet
         htmlAttributes={{
            lang,
         }}
         title={title}
         titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
         image={image}
         link={
            canonical
               ? [
                    {
                       rel: 'canonical',
                       href: canonical,
                    },
                 ]
               : []
         }
         meta={[
            {
               name: `description`,
               content: metaDescription,
            },
            {
               name: 'image',
               content: image || cardImg.childImageSharp.original.src,
            },
            {
               name: 'keywords',
               content: site.siteMetadata.keywords.join(','),
            },
            {
               property: `og:title`,
               content: title,
            },
            {
               property: `og:description`,
               content: metaDescription,
            },
            {
               property: `og:type`,
               content: `website`,
            },
            {
               name: `twitter:card`,
               content: `summary`,
            },
            {
               name: `twitter:creator`,
               content: site.siteMetadata?.author || ``,
            },
            {
               name: `twitter:title`,
               content: title,
            },
            {
               name: `twitter:description`,
               content: metaDescription,
            },
         ]
            .concat(
               metaImage
                  ? [
                       {
                          property: 'og:image',
                          content:
                             image || cardImg.childImageSharp.original.src,
                       },
                       {
                          property: 'og:image:width',
                          content:
                             metaImage.width ||
                             cardImg.childImageSharp.original.width,
                       },
                       {
                          property: 'og:image:height',
                          content:
                             metaImage.height ||
                             cardImg.childImageSharp.original.height,
                       },
                       {
                          name: 'twitter:card',
                          content: 'summary_large_image',
                       },
                    ]
                  : [
                       {
                          name: 'twitter:card',
                          content: 'summary',
                       },
                    ]
            )
            .concat(meta)}
      />
   );
}

Seo.defaultProps = {
   lang: `en`,
   meta: [],
   description: ``,
};

Seo.propTypes = {
   description: PropTypes.string,
   lang: PropTypes.string,
   meta: PropTypes.arrayOf(PropTypes.object),
   title: PropTypes.string.isRequired,
};

export default Seo;
