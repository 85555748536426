import React, { useState, useEffect } from 'react';
import { FaChevronUp } from 'react-icons/fa';
import { animateScroll as scroll } from 'react-scroll';
import styled from 'styled-components';

const BackToTop = () => {
   const [show, setShow] = useState(false);

   const showOnScroll = () => {
      if (window.scrollY >= 400) {
         setShow(true);
      } else {
         setShow(false);
      }
   };

   useEffect(() => {
      window.addEventListener('scroll', showOnScroll);
   }, []);

   const toggleHome = () => {
      scroll.scrollToTop();
   };

   return (
      <BackToTopWrap
         className={show ? 'to-top active' : 'to-top'}
         onClick={toggleHome}
      >
         <FaChevronUp />
      </BackToTopWrap>
   );
};

export default BackToTop;

const BackToTopWrap = styled.div`
   cursor: pointer;
   position: fixed;
   display: grid;
   place-items: center;
   background-color: ${(props) => props.theme.colors.gray};
   color: ${(props) => props.theme.colors.white};
   width: 30px;
   height: 30px;
   bottom: 10%;
   right: 4%;
   border-radius: 50%;
   transition: ${(props) => props.theme.misc.transitionEase};
   visibility: hidden;
   opacity: 0;
   z-index: 9;

   &:hover {
      background-color: ${(props) => props.theme.colors.gray};
   }

   &.active {
      visibility: visible;
      opacity: 1;
   }
   @media screen and (min-width: 1024px) {
      width: 40px;
      height: 40px;
   }
`;
