import * as React from 'react';
import { Provider } from 'react-redux';
import { store } from '../../redux/store';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { Header, Footer } from '..';
import { GlobalStyle, ThemeContext } from '../../styles/globalStyle';
import styled from 'styled-components';
import BackToTop from './BackToTop';

const Layout = ({ children }) => {
   const data = useStaticQuery(graphql`
      query SiteTitleQuery {
         site {
            siteMetadata {
               title
            }
         }
      }
   `);

   return (
      <Provider store={store}>
         <ThemeContext>
            <GlobalStyle />
            <Header siteTitle={data.site.siteMetadata.title || `Title`} />
            <PageContent>{children}</PageContent>
            <BackToTop />
            <Footer />
         </ThemeContext>
      </Provider>
   );
};

Layout.propTypes = {
   children: PropTypes.node.isRequired,
};

export default Layout;

const PageContent = styled.main`
   min-height: calc(100vh - 150px);
`;
