import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { breakpoints } from '../../styles/globalStyle';

const FreightServicesGrid = ({ list = [] }) => {
   return (
      <FreightServicesGridWrap>
         <FreightServicesInner className='container'>
            {list.length > 0 &&
               list.map((item) => {
                  return (
                     <article key={item.id}>
                        <GatsbyImage
                           image={item.freightImage.gatsbyImageData}
                           alt={item.freightItemTitle}
                        />
                        <div className='info'>
                           <h4>{item.freightItemTitle}</h4>
                           <Link to='/shippers-quote'>
                              <button className='dark-btn'>Get Quote</button>
                           </Link>
                        </div>
                     </article>
                  );
               })}
         </FreightServicesInner>
      </FreightServicesGridWrap>
   );
};

export default FreightServicesGrid;

const FreightServicesGridWrap = styled.section`
   padding-top: 0;
`;

const FreightServicesInner = styled.div`
   display: grid;
   gap: 2rem;

   article {
      border-radius: 10px;
      overflow: hidden;
      transition: ${(props) => props.theme.misc.transitionEase};
      background-color: ${(props) => props.theme.colors.gray1};

      &:hover {
         background-color: ${(props) => props.theme.colors.gray2};
      }

      .info {
         padding: 1.5rem;
      }

      h4 {
         text-transform: capitalize;
      }
   }

   @media screen and (min-width: ${breakpoints.tablet}px) {
      grid-template-columns: repeat(2, 1fr);
   }

   @media screen and (min-width: ${breakpoints.desktop}px) {
      h4 {
         font-size: 1.25rem;
      }
   }
`;
