import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../styles/globalStyle';
import { StructuredText } from 'react-datocms';

const About = ({ title, subtitle, desc, img, altDir }) => {
   console.log(img);
   return (
      <AboutWrap id='about'>
         <AboutInner className='container'>
            {title && <h2>{title}</h2>}
            <div className={altDir ? 'alt-dir image-text' : 'image-text'}>
               <GatsbyImage image={img.gatsbyImageData} alt={title} />

               <div className='content'>
                  <h3>{subtitle}</h3>
                  <StructuredText data={desc} />
               </div>
            </div>
         </AboutInner>
      </AboutWrap>
   );
};

export default About;

const AboutWrap = styled.section``;

const AboutInner = styled.div`
   position: relative;

   h2 {
      margin-bottom: 2rem;
   }

   .image-text {
      display: grid;
      gap: 2rem;

      .gatsby-image-wrapper {
         border-radius: 10px;
         height: 100%;
         overflow: hidden;
      }
   }

   @media screen and (min-width: ${breakpoints.desktop}px) {
      .image-text {
         grid-template-columns: repeat(2, 1fr);
         grid-template-areas: 'image content';
         align-items: center;
         gap: 2.5rem;

         .gatsby-image-wrapper {
            grid-area: image;
            object-position: center;

            img {
               object-position: center;
            }
         }

         .content {
            grid-area: content;
         }

         &.alt-dir {
            grid-template-areas: 'content image';
         }
      }

      h2 {
         font-size: 1.825rem;
      }

      h3 {
         font-size: 1.7rem;
      }
   }
`;
