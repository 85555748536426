import { createSlice } from '@reduxjs/toolkit';

const initialState = {
   firstName: '',
   lastName: '',
   email: '',
   phone: '',
   MCNumber: '',
   truckType: '',
   locationDetails: '',
   dateLoad: '',
   success: false,
};

export const getQuoteSlice = createSlice({
   name: 'Quote form',
   initialState,
   reducers: {
      editForm: (state, action) => {
         if (action.payload.name === 'firstName') {
            state.firstName = action.payload.value;
         }

         if (action.payload.name === 'lastName') {
            state.lastName = action.payload.value;
         }

         if (action.payload.name === 'email') {
            state.email = action.payload.value;
         }

         if (action.payload.name === 'phone') {
            state.phone = action.payload.value;
         }

         if (action.payload.name === 'MCNumber') {
            state.MCNumber = action.payload.value;
         }

         if (action.payload.name === 'truckType') {
            state.truckType = action.payload.value;
         }

         if (action.payload.name === 'locationDetails') {
            state.locationDetails = action.payload.value;
         }
         if (action.payload.name === 'dateLoad') {
            state.dateLoad = action.payload.value;
         }
      },
      formSuccess: (state) => {
         state.success = true;
      },
      resetForm: (state) => {
         state.firstName = '';
         state.lastName = '';
         state.email = '';
         state.phone = '';
         state.MCNumber = '';
         state.truckType = '';
         state.locationDetails = '';
         state.dateLoad = '';
         state.success = false;
      },
   },
});

// Action creators are generated for each case reducer function
export const { editForm, resetForm, formSuccess } = getQuoteSlice.actions;

export const selectGetQuote = (state) => state.getQuote;

export default getQuoteSlice.reducer;
