import React from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../styles/globalStyle';
import { FaFacebook, FaTwitter, FaLinkedin } from 'react-icons/fa';

const FooterContent = () => {
   return (
      <FooterContentWrap>
         <FooterInner className='container'>
            <Contact>
               <ul>
                  <li>
                     <p>
                        Shipper:{' '}
                        <a href='mailto:clientservices@barcodebuildup.com'>
                           clientservices@barcodebuildup.com
                        </a>
                     </p>
                  </li>
                  <li>
                     <p>
                        Carrier:{' '}
                        <a href='mailto:carrierservices@barcodebuildup.com'>
                           carrierservices@barcodebuildup.com
                        </a>
                     </p>
                  </li>
                  <li>
                     <p>
                        Dispatch:{' '}
                        <a href='mailto:dispatch@barcodebuildup.com'>
                           dispatch@barcodebuildup.com
                        </a>
                     </p>
                  </li>
                  <li>
                     <p>
                        Call: <a href='tel:+15619265522'>561-926-5522</a>
                     </p>
                  </li>
               </ul>
               <Social>
                  <li>
                     <a
                        href='https://www.facebook.com'
                        target='_blank'
                        rel='noreferrer'
                     >
                        <FaFacebook className='icon' />
                     </a>
                  </li>
                  <li>
                     <a
                        href='https://www.twitter.com'
                        target='_blank'
                        rel='noreferrer'
                     >
                        <FaTwitter className='icon' />
                     </a>
                  </li>
                  <li>
                     <a
                        href='https://www.linkedin.com/'
                        target='_blank'
                        rel='noreferrer'
                     >
                        <FaLinkedin className='icon' />
                     </a>
                  </li>
               </Social>
            </Contact>
         </FooterInner>
      </FooterContentWrap>
   );
};

export default FooterContent;

const FooterContentWrap = styled.div`
   padding: 3rem 0 1.5rem;
   background-color: ${(props) => props.theme.colors.black};

   @media screen and (min-width: ${breakpoints.desktop}px) {
      padding: 5rem 0 2.5rem;
   }
`;
const FooterInner = styled.div`
   p,
   a {
      transition: ${(props) => props.theme.misc.transitionEase};
      color: ${(props) => props.theme.colors.gray7};
      font-size: 20px;
   }

   a {
      color: ${(props) => props.theme.colors.gray3};

      &:hover {
         color: ${(props) => props.theme.colors.gray7};
      }
   }

   @media screen and (min-width: ${breakpoints.desktop}px) {
      a {
         font-size: 30px;
      }

      p {
         font-size: 30px;
         line-height: 2.5rem;
      }
   }
`;

const Social = styled.ul`
   display: flex;
   gap: 2rem;
   margin-top: 1rem;

   li {
      margin: 0;
      line-height: 0;
   }

   .icon {
      font-size: 1.5rem;
   }

   @media screen and (min-width: ${breakpoints.desktop}px) {
      margin-top: unset;

      .icon {
         font-size: 2.5rem;
      }
   }
`;

const Contact = styled.div`
   li {
      margin: 1rem 0;
   }

   @media screen and (min-width: ${breakpoints.desktop}px) {
      li {
         margin: 1.25rem 0;
      }
   }
`;
