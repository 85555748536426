import { Link } from 'gatsby';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../styles/globalStyle';

const PageHeader = ({ title, description, breadCrumbs, bgImg }) => {
   return (
      <PageHeaderWrap>
         {bgImg ? (
            <GatsbyImage
               style={{
                  gridArea: '1/1',
                  height: '100%',
                  objectFit: 'cover',
                  objectPosition: 'center',
               }}
               image={bgImg}
               alt=''
            />
         ) : (
            <StaticImage
               style={{
                  gridArea: '1/1',
                  objectFit: 'cover',
                  objectPosition: 'center',
               }}
               src='../../images/bg-17.jpg'
               alt=''
               layout='fullWidth'
               formats={['auto', 'webp', 'avif']}
            />
         )}
         <PageHeaderInner className='container'>
            <p className='bread-crumbs'>
               <Link to='/'>Home</Link> {' / '}{' '}
               {breadCrumbs ? (
                  <Link to={`/${breadCrumbs}`}>{breadCrumbs}</Link>
               ) : null}
               {breadCrumbs ? ` / ` : null}
               <span>{title ? title : 'Default Title'}</span>
            </p>
            <h1>{title ? title : 'Default Title'}</h1>
            {description && <p>{description}</p>}
         </PageHeaderInner>
      </PageHeaderWrap>
   );
};

export default PageHeader;

const PageHeaderWrap = styled.section`
   padding: 0;
   display: grid;
   height: 300px;
   overflow: hidden;
   position: relative;

   &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.7);
   }

   @media screen and (min-width: ${breakpoints.desktop}px) {
      height: 350px;
   }
`;

const PageHeaderInner = styled.div`
   position: relative;
   padding: 75px 1.5rem 0;
   grid-area: 1 / 1;
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   gap: 0.05rem;
   z-index: 1;

   h1 {
      color: ${(props) => props.theme.colors.white};
   }

   p {
      color: ${(props) => props.theme.colors.white};

      a {
         font-weight: bold;
         color: ${(props) => props.theme.colors.white};
         font-size: 0.8rem;
         text-transform: capitalize;
      }
   }

   .bread-crumbs {
      font-size: 0.8rem;
   }

   @media screen and (min-width: ${breakpoints.desktop}px) {
      padding: 75px 6rem 0;

      h1 {
         font-size: 56px;
      }

      p {
         a {
            font-size: 1rem;
         }
      }

      .bread-crumbs {
         font-size: 1rem;
      }
   }
`;
