import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { breakpoints } from '../../styles/globalStyle';

const Services = ({ services }) => {
   return (
      <ServicesWrap>
         <div className='container'>
            <div className='services-intro'>
               <h2>Our Services</h2>
               <h3>
                  Barcode Buildup - one of the best in class freight delivery
                  services.
               </h3>
            </div>
            <div className='services-grid'>
               {services.map((s) => (
                  <article key={s.id}>
                     <Link to={`/services/${s.serviceSlug}`}>
                        <GatsbyImage
                           image={s.serviceImage.gatsbyImageData}
                           alt={s.serviceTitle}
                        />
                        <div className='info'>
                           <h3>{s.serviceTitle}</h3>
                        </div>
                     </Link>
                  </article>
               ))}
            </div>
         </div>
      </ServicesWrap>
   );
};

export default Services;

const ServicesWrap = styled.section`
   padding-bottom: 0;
   max-width: 1150px;
   margin: auto;

   .services-intro {
      margin-bottom: 2rem;
   }

   .services-grid {
      display: grid;
      gap: 2rem;

      article {
         border-radius: 10px;
         overflow: hidden;
         transition: ${(props) => props.theme.misc.transitionEase};

         .gatsby-image-wrapper {
            min-height: 250px;
         }

         h3 {
            text-transform: unset;
            text-align: center;
            margin-top: 1rem;
         }

         p {
            font-style: italic;
         }
         &:hover {
            background-color: ${(props) => props.theme.colors.gray1};
         }
      }

      @media screen and (min-width: ${breakpoints.desktop}px) {
         article {
            .gatsby-image-wrapper {
               min-height: 300px;
            }
         }

         grid-template-columns: repeat(3, 1fr);
      }
   }
`;
