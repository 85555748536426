import React from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Fade } from 'react-slideshow-image';
import { breakpoints } from '../../styles/globalStyle';
import 'react-slideshow-image/dist/styles.css';
import { StructuredText } from 'react-datocms';

const Culture = ({ slides }) => {
   console.log(slides);
   return (
      <CultureWrap>
         <CultureInner className='slide-container container'>
            <Fade>
               {slides.map((slide, index) => (
                  <div className='each-fade' key={index}>
                     <div className='image'>
                        <GatsbyImage
                           image={slide.image.gatsbyImageData}
                           alt={slide.title}
                        />
                     </div>
                     <div className='content'>
                        <h2>{slide.title}</h2>
                        <StructuredText data={slide.desc} />
                     </div>
                  </div>
               ))}
            </Fade>
         </CultureInner>
      </CultureWrap>
   );
};

export default Culture;

const CultureWrap = styled.section`
   background-color: ${(props) => props.theme.colors.black};
`;

const CultureInner = styled.div`
   position: relative;

   .each-fade {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
   }

   .image {
      border-radius: 10px;
      height: 400px;
      overflow: hidden;
      margin-bottom: 1.5rem;
      width: 275px;

      .gatsby-image-wrapper {
         height: 100%;
         width: 100%;
      }
   }

   .content {
      ul {
         list-style: initial;
         color: white;
         padding-left: 1rem;
      }

      h2,
      p {
         color: ${(props) => props.theme.colors.white};
      }
   }

   .react-slideshow-container .default-nav {
      position: absolute;
      top: 100%;

      &:first-of-type {
         left: 0;
      }
      &:last-of-type {
         right: 0;
      }
   }

   @media screen and (min-width: ${breakpoints.desktop}px) {
      .each-fade {
         display: flex;
         flex-direction: row-reverse;
         align-items: center;
         gap: 6rem;
         padding: 0 3rem;
      }

      .image {
         display: block;
         height: unset;
         width: unset;
         flex: 0.4;

         .gatsby-image-wrapper {
            height: 100%;
         }
      }

      .content {
         flex: 1;
      }

      .react-slideshow-container .default-nav {
         position: relative;
         top: unset;

         &:first-of-type {
            left: unset;
         }
         &:last-of-type {
            right: unset;
         }
      }
   }
`;
