import React from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';

const theme = {
   fonts: {
      primary: `'Montserrat', sans-serif`,
      secondary: `'Open Sans', sans-serif`,
      hero: `'Raleway', sans-serif`,
   },
   weights: {
      normal: 400,
      semiBold: 600,
      bold: 700,
   },
   colors: {
      primary: '#212529',
      secondary: '#343A40',
      neutral: '#F6F6F6',
      white: '#FFF',
      gray: '#212529',
      gray1: '#F6F6F8',
      gray2: '#DEE2E6',
      gray3: '#CED4DA',
      gray4: '#ADB5BD',
      gray5: '#6C757D',
      gray6: '#495057',
      gray7: '#343A40',
      gray8: '#212529',
      black: '#000',
   },
   sizes: {
      xsmall: '12px',
      small: '14px',
      normal: '16px',
      medium: '18px',
      large: '20px',
      xlarge: '24px',
   },
   headings: {
      xsmall: '16px',
      small: '18px',
      normal: '20px',
      medium: '24px',
      large: '30px',
      xlarge: '40px',
      xxlarge: '48px',
   },
   roundings: {
      small: '2px',
      medium: '8px',
      large: '16px',
   },
   misc: {
      spacing: '2px',
      transitionEase: 'all 0.2s linear',
      transitionEase1: 'all 0.5s ease-in-out',
      shadow: '5px 5px 20px 0 rgb(255 255 255 / 3%)',
   },
};

const breakpoints = {
   mobile: 400,
   phablet: 550,
   tablet: 768,
   desktop: 1024,
   hd: 1280,
};

const dimensions = {
   headerHeight: '75px',
   maxWidth: '1600px',
};

const GlobalStyle = createGlobalStyle`
   *, ::before, ::after {
      box-sizing: border-box;
      padding: 0;
      margin: 0;
   }

   html {
      position: relative;
      scroll-behavior: smooth;
   }

   body {
      font-family: ${(props) => props.theme.fonts.secondary};
      font-weight: ${(props) => props.theme.weights.normal};
      color:  ${(props) => props.theme.colors.gray7};
      max-width: ${dimensions.maxWidth};
      letter-spacing: 1px;
      font-weight: 400;
   }
   
   main {
      min-height:calc(100vh - ${dimensions.headerHeight.desktop}); 
   }

   img {
     width: 100%;
   }
   
   h1, h2, h3, h4, h5, h6 {
      font-family: ${(props) => props.theme.fonts.primary};
      font-weight: ${(props) => props.theme.weights.bold};
      margin-bottom: ${(props) => props.theme.sizes.normal};
      color: ${(props) => props.theme.colors.gray};
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 2px;
   }
   
   p,a {
      margin-bottom: ${theme.sizes.normal};
      line-height: 32px;
      font-size: 0.9rem;
      
      @media screen and (min-width:${breakpoints.desktop}px) {
         font-size: 1rem;
      }
   }

   a {
      text-decoration: none;
   }

   ul {
      list-style:none;
   }

   li {
      font-size: 0.9rem;
      
      
      @media screen and (min-width:${breakpoints.desktop}px) {
         font-size: 1rem;
      }
   }

   section {
      padding: 3rem 0;
      
      @media screen and (min-width:${breakpoints.desktop}px) {
         padding: 6rem 0;
      }
   }

   button {
      cursor: pointer;
      font-family: ${(props) => props.theme.fonts.secondary};
      font-weight: 400;
      letter-spacing: 2px;
      color: ${(props) => props.theme.colors.white};
   }

   .container {
      width:95%;
      margin: auto;
      max-width: 1600px;
      
      @media screen and (min-width:${breakpoints.desktop}px) {
         width:90%;
      }
   }

   .btn, .dark-btn {
      background: ${(props) => props.theme.colors.white};
      color: ${(props) => props.theme.colors.gray};
      font-size: 0.8rem;
      border: none;
      padding: 0.65rem 1.25rem;
      outline: none;
      transition: ${(props) => props.theme.misc.transitionEase};
      text-transform: capitalize;
      border-radius: 5px;
      
      &:hover {
         background: ${(props) => props.theme.colors.gray7};        
      }
      
      @media screen and (min-width:${breakpoints.desktop}px) {
         padding: 0.9rem 1.5rem;
         font-size: 1rem;
         text-transform: uppercase;
         margin-top: 1rem;
      }
   }
   
   .dark-btn {
      background: ${(props) => props.theme.colors.black};
      color: ${(props) => props.theme.colors.white};   
   }
   
   @media screen and (min-width:${breakpoints.desktop}px) {
      h2 {
         font-size: 1.875rem;
      }
   }
`;
const ThemeContext = ({ children }) => (
   <ThemeProvider theme={theme}>{children} </ThemeProvider>
);

export { GlobalStyle, ThemeContext, theme, breakpoints, dimensions };
