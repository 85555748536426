import React from 'react';
import styled from 'styled-components';

import { breakpoints } from '../../styles/globalStyle';
import ContactFormComp from '../shared/ContactFormComp';

const ContactDetails = () => {
   return (
      <ContactDetailsWrap>
         <ContactDetailsInner className='container'>
            {/* Contact Boxes */}
            <ContactBoxes>
               <ContactBox>
                  <h4>Shipper</h4>
                  <a href='mailto:clientservices@barcodebuildup.com'>
                     clientservices@barcodebuildup.com
                  </a>
               </ContactBox>
               <ContactBox>
                  <h4>Carrier</h4>
                  <a href='mailto:carrierservices@barcodebuildup.com'>
                     carrierservices@barcodebuildup.com
                  </a>
               </ContactBox>
               <ContactBox>
                  <h4>Dispatch</h4>
                  <a href='mailto:dispatch@barcodebuildup.com'>
                     dispatch@barcodebuildup.com
                  </a>
               </ContactBox>
               <ContactBox>
                  <h4>Call:</h4>
                  <a href='tel:+15619265522'>561-926-5522</a>
               </ContactBox>
            </ContactBoxes>
            {/* Contact Boxes End*/}

            {/* Contact Form */}
            <ContactFormComp formName='contact' />
            {/* Contact Form End */}
         </ContactDetailsInner>
      </ContactDetailsWrap>
   );
};

export default ContactDetails;

const ContactDetailsWrap = styled.section``;

const ContactDetailsInner = styled.div`
   position: relative;
   @media screen and (min-width: ${breakpoints.desktop}px) {
      display: grid;
      align-items: flex-start;
      gap: 3rem;
      grid-template-columns: 1fr 2fr;
   }
`;

const ContactBoxes = styled.div`
   display: none;
   background: ${(props) => props.theme.colors.gray1};
   padding: 2rem;

   @media screen and (min-width: ${breakpoints.desktop}px) {
      display: block;
      position: sticky;
      top: 50px;
   }
`;

const ContactBox = styled.div`
   margin-bottom: 2rem;
   h4 {
      color: ${(props) => props.theme.colors.gray};
      font-size: 18px;
      margin-bottom: 0.5rem;
   }

   a {
      color: ${(props) => props.theme.colors.black};
      transition: all 0.2s ease-in-out;

      &:hover {
         color: ${(props) => props.theme.colors.gray7};
      }
   }
`;
