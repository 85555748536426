import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../styles/globalStyle';
import { BsArrowRightSquareFill } from 'react-icons/bs';

const ImageText = ({
   altDir,
   img,
   title = 'Default Title',
   list = [],
   description,
}) => {
   return (
      <ImageTextWrap className={altDir && 'alt-dir'}>
         <ImageTextInner className={altDir ? 'alt-dir container' : 'container'}>
            <div className='image'>
               <GatsbyImage
                  image={img.gatsbyImageData}
                  alt={title}
                  height={100}
               />
            </div>
            <div className='content'>
               <h2>{title}</h2>
               {description ? <p>{description}</p> : null}
               {list.length > 0 && (
                  <Lists>
                     {list.map((item) => {
                        return (
                           <li key={item.id}>
                              <BsArrowRightSquareFill className='icon' />
                              <span>{item.listItem}</span>
                           </li>
                        );
                     })}
                  </Lists>
               )}
            </div>
         </ImageTextInner>
      </ImageTextWrap>
   );
};

export default ImageText;

const ImageTextWrap = styled.section`
   /* padding-top: 0; */

   &.alt-dir {
      background-color: ${(props) => props.theme.colors.gray1};
   }
`;

const ImageTextInner = styled.div`
   position: relative;

   .content {
      margin-top: 1.5rem;
   }

   @media screen and (min-width: ${breakpoints.desktop}px) {
      display: flex;
      gap: 3rem;
      align-items: center;

      .image {
         flex: 1;
         height: 500px;
         border-radius: 10px;
         overflow: hidden;

         .gatsby-image-wrapper {
            object-fit: cover;
            height: 100%;
         }
      }

      .content {
         flex: 1;
         padding: unset;
         margin: unset;

         h2 {
            font-size: 1.875rem;
         }
      }

      &.alt-dir {
         flex-direction: row-reverse;

         .content {
            left: 0;
            right: unset;
         }
      }
   }
`;

const Lists = styled.ul`
   li {
      margin: 0.5rem 0;
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: start;
      gap: 0.75rem;
      line-height: 25px;

      .icon {
         font-size: 0.9rem;
         margin-top: 7px;
      }

      @media screen and (min-width: ${breakpoints.desktop}px) {
      }
   }
`;
