import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../styles/globalStyle';
import { FaChevronLeft } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import {
   closeDropdown,
   selectdropdownOpen,
   closeMenu,
} from '../../redux/features/globalSlice';

const Dropdown = ({ dropdown, id }) => {
   const dispatch = useDispatch();
   const dropdownOpen = useSelector(selectdropdownOpen);

   return (
      <DropdownWrap
         className={dropdownOpen.status && dropdownOpen.id === id && 'opened'}
      >
         <li className='back'>
            <button onClick={() => dispatch(closeDropdown())}>
               <FaChevronLeft /> Back to Menu
            </button>
         </li>
         {dropdown.map((item) => (
            <li key={item.id}>
               <Link
                  to={item.dropdownAddress}
                  activeClassName='active'
                  onClick={() => dispatch(closeMenu())}
               >
                  {item.dropdownTitle}
               </Link>
            </li>
         ))}
      </DropdownWrap>
   );
};

export default Dropdown;

const DropdownWrap = styled.ul`
   position: fixed;
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
   background: ${(props) => props.theme.colors.black};
   transform: translateX(100%);
   transition: ${(props) => props.theme.misc.transitionEase};
   opacity: 0;
   z-index: -2;
   visibility: hidden;

   &.opened {
      transition: ${(props) => props.theme.misc.transitionEase};
      transform: translateX(0);
      opacity: 1;
      z-index: 15;
      visibility: visible;
   }

   a {
      display: block;
      padding: 1.75rem 2rem;
      border-bottom: ${(props) => props.theme.colors.gray8} 1px solid;
      letter-spacing: 2px;
      color: ${(props) => props.theme.colors.white};
      width: 100%;
      margin-bottom: 0;
      font-weight: 400;

      &:hover,
      &.active {
         background-color: ${(props) => props.theme.colors.gray7};
         color: ${(props) => props.theme.colors.gray2};
         transition: ${(props) => props.theme.misc.transitionEase};
      }
   }

   .back {
      button {
         padding: 1rem 2rem;
         width: 100%;
         display: flex;
         align-items: center;
         gap: 1rem;
         background: ${(props) => props.theme.colors.gray7};
         border: none;
         outline: none;
         border-bottom: ${(props) => props.theme.colors.gray7} 1px solid;
      }
   }

   @media screen and (min-width: ${breakpoints.desktop}px) {
      border-top: ${(props) => props.theme.colors.gray8} 2px solid;
      position: absolute;
      width: unset;
      height: unset;
      top: 150%;
      width: 250px;
      left: unset;
      right: 0;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: stretch;
      transform: unset;

      a {
         display: block;
         text-transform: capitalize;
         padding: 1rem !important;
         display: flex;
         align-items: center;
         border-bottom: ${(props) => props.theme.colors.gray7} 1px solid;
         transition: ${(props) => props.theme.misc.transitionEase};
         background: ${(props) => props.theme.colors.gray1};
         color: ${(props) => props.theme.colors.black};
         margin-bottom: 0;

         &:hover,
         &.active {
            background-color: ${(props) => props.theme.colors.gray3};
            color: ${(props) => props.theme.colors.gray8};
         }
      }

      .back {
         display: none;
      }
   }
`;
