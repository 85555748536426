import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

import { breakpoints } from '../../styles/globalStyle';

const ServicesGrid = ({ services }) => {
   return (
      <ServicesGridWrap>
         <ServicesGridInner className='container'>
            {services.map((s) => (
               <article key={s.id}>
                  <Link to={s.serviceSlug}>
                     <GatsbyImage
                        image={s.serviceImage.gatsbyImageData}
                        alt={s.serviceTitle}
                     />

                     <h3>{s.serviceTitle}</h3>
                  </Link>
               </article>
            ))}
         </ServicesGridInner>
      </ServicesGridWrap>
   );
};

export default ServicesGrid;

const ServicesGridWrap = styled.section``;
const ServicesGridInner = styled.div`
   display: grid;
   gap: 2rem;

   article {
      border-radius: 10px;
      overflow: hidden;
      transition: ${(props) => props.theme.misc.transitionEase};

      .gatsby-image-wrapper {
         min-height: 250px;
      }

      h3 {
         text-transform: unset;
         text-align: center;
         margin-top: 1rem;
      }

      &:hover {
         background-color: ${(props) => props.theme.colors.gray1};
      }
   }

   @media screen and (min-width: ${breakpoints.desktop}px) {
      grid-template-columns: repeat(3, 1fr);

      article {
         .gatsby-image-wrapper {
            min-height: 300px;
         }
      }

      h3 {
         font-size: 1.5rem;
      }
   }
`;
