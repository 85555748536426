import React, { useEffect } from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../styles/globalStyle';
import { useDispatch, useSelector } from 'react-redux';

import { encode } from '../../utils/helpers';
import {
   resetContactForm,
   selectContactForm,
   formSuccess,
   editContactForm,
} from '../../redux/features/contactFormSlice';

const ContactFormComp = ({ formName }) => {
   const dispatch = useDispatch();
   const formValues = useSelector(selectContactForm);

   const handleSubmit = (event) => {
      event.preventDefault();

      fetch('/', {
         method: 'POST',
         headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
         body: encode({
            'form-name': event.target.getAttribute('name'),
            ...formValues,
         }),
      })
         .then(() => dispatch(formSuccess()))
         .catch((error) => console.log(error.message));
   };

   const handleChange = (event) => {
      dispatch(
         editContactForm({
            name: event.target.name,
            value: event.target.value,
         })
      );
   };

   useEffect(() => {
      dispatch(resetContactForm());
      // eslint-disable-next-line
   }, []);

   return (
      <ContactForm>
         {formValues.success ? (
            <div className='thanks'>
               <h1>Thanks for your message</h1>

               <button
                  className='dark-btn'
                  onClick={() => dispatch(resetContactForm())}
               >
                  Send Another message
               </button>
            </div>
         ) : (
            <Form
               className='form'
               name={`${formName}Form`}
               onSubmit={handleSubmit}
               method='post'
               data-netlify='true'
            >
               <input
                  type='hidden'
                  name='form-name'
                  value={`${formName}Form`}
               />
               <div className='form-control'>
                  <label htmlFor='service'>service</label>
                  <select name='service' required onChange={handleChange}>
                     <option value='value' default selected disabled>
                        I am interested in
                     </option>
                     <option value='Full Truckload (LTL)'>
                        Full Truckload (LTL)
                     </option>
                     <option value='Less Than Truckload (LTL)'>
                        Less Than Truckload (LTL)
                     </option>
                     <option value='Specialty Freight'>
                        Specialty Freight
                     </option>
                     <option value='Flatbed'>Flatbed</option>

                     <option value='Other'>Other</option>
                  </select>
               </div>
               <div className='form-control'>
                  <label htmlFor='name'>Name:</label>
                  <input
                     aria-label='name'
                     type='text'
                     name='name'
                     placeholder='Name'
                     required
                     onChange={handleChange}
                     value={formValues.name}
                  />
               </div>

               <div className='form-control'>
                  <label htmlFor='email'>Email Address</label>
                  <input
                     aria-label='email'
                     type='email'
                     name='email'
                     placeholder='Email Address'
                     required
                     onChange={handleChange}
                     value={formValues.email}
                  />
               </div>
               <div className='form-control'>
                  <label htmlFor='phone'>Phone</label>
                  <input
                     aria-label='phone'
                     type='phone'
                     name='phone'
                     placeholder='Phone'
                     required
                     onChange={handleChange}
                     value={formValues.phone}
                  />
               </div>
               <div className='form-control'>
                  <label htmlFor='company'>Company</label>
                  <input
                     aria-label='company'
                     type='company'
                     name='company'
                     placeholder='Company'
                     required
                     onChange={handleChange}
                     value={formValues.company}
                  />
               </div>

               <div className='form-control'>
                  <label htmlFor='message'>message</label>
                  <textarea
                     aria-label='message'
                     name='message'
                     cols='30'
                     rows='5'
                     placeholder='How Can We Help You?'
                     required
                     onChange={handleChange}
                     value={formValues.message}
                  />
               </div>
               {/* <div
                     class='g-recaptcha'
                     data-sitekey={process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY}
                  ></div> */}
               <button type='submit' className='dark-btn'>
                  Send us a message
               </button>
            </Form>
         )}
      </ContactForm>
   );
};

export default ContactFormComp;

const ContactForm = styled.div`
   .thanks {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 300px;
   }
`;

const Form = styled.form`
   /* margin-top: 2rem; */
   .form-control {
      margin-bottom: 1rem;

      & label {
         display: none;
      }

      & input,
      & textarea,
      & select {
         font-size: 1rem;
         border: 0.5px solid rgba(21, 71, 52, 0.5);
         display: block;
         width: 100%;
         padding: 1rem;
         font-family: ${(props) => props.theme.fonts.secondary};
         letter-spacing: 1.5px;
         border-radius: 5px;
      }
      & input::placeholder,
      & textarea::placeholder {
         font-family: ${(props) => props.theme.fonts.secondary};
         letter-spacing: 1.5px;
      }
      & input:focus,
      & textarea:focus,
      & select:focus {
         outline: 0;
         border-color: ${(props) => props.theme.colors.gray7};
      }
      &.success input {
         border-color: green;
      }
      &.error input {
         border-color: red;
      }
      & small {
         display: block;
         font-size: 0.75rem;
         font-style: italic;
         font-weight: 600;
         text-align: left;
         color: red;
         bottom: 0;
         left: 0;
         margin-top: 5px;
         visibility: hidden;
      }
      #g-recaptcha-response {
         display: block !important;
         position: absolute;
         margin: -50px 0 0 0 !important;
         z-index: -999999;
         opacity: 0;
      }
      &.error small {
         visibility: visible;
         margin-bottom: 1rem;
      }
   }
`;
