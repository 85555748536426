import React from 'react';
import styled from 'styled-components';
import { dimensions } from '../../styles/globalStyle';

export default function Copyright({ title }) {
   return (
      <WebsiteCopyright>
         <div className='container'>
            <p>
               <span>
                  &copy; {title} {new Date().getFullYear()}. All Rights Reserved
               </span>{' '}
               <br />{' '}
               {/* <span>
                  Developed by{' '}
                  <a
                     href='https://www.devubong.com/'
                     target='_blank'
                     rel='noreferrer'
                  >
                     Dev Ubong
                  </a>
               </span> */}
            </p>
         </div>
      </WebsiteCopyright>
   );
}

export const WebsiteCopyright = styled.div`
   display: flex;
   align-items: center;
   height: ${dimensions.headerHeight};
   background-color: ${(props) => props.theme.colors.black};

   p {
      margin: 0;
      color: ${(props) => props.theme.colors.gray4};
      line-height: 10px;
   }

   a {
      color: ${(props) => props.theme.colors.gray7};
      font-size: 25px;

      &:hover {
         opacity: 0.7;
         transition: 0.3s all ease-in-out;
      }
   }
`;
