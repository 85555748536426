import React from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { breakpoints } from '../../styles/globalStyle';
import { useDispatch, useSelector } from 'react-redux';
import {
   editForm,
   resetForm,
   selectDispatchForm,
} from '../../redux/features/dispatchFormSlice';
import { encode } from '../../utils/helpers';

const DispatchForm = () => {
   const dispatch = useDispatch();
   const dispatchForm = useSelector(selectDispatchForm);

   console.log(dispatchForm);

   const handleSubmit = (event) => {
      event.preventDefault();

      fetch('/', {
         method: 'POST',
         headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
         body: encode({
            'form-name': event.target.getAttribute('name'),
            ...dispatchForm,
         }),
      })
         .then(() => navigate('/thank-you'))
         .then(() => dispatch(resetForm()))
         .catch((error) => console.log(error.message));
   };

   const handleChange = (event) => {
      console.log(event.target.value);
      dispatch(
         editForm({
            name: event.target.name,
            value: event.target.value,
         })
      );
   };

   return (
      <DispatchFormWrap id='dispatch'>
         <div className='container'>
            <h2>Dispatch Load Request Form.</h2>
            <form
               name='dispatchRequest'
               id='dispatchRequest'
               method='post'
               data-netlify='true'
               onSubmit={handleSubmit}
            >
               <input type='hidden' name='form-name' value='dispatchRequest' />
               <div className='form-group'>
                  <p>
                     <label>
                        First Name:{' '}
                        <input
                           type='text'
                           name='firstName'
                           value={dispatchForm.firstName}
                           onChange={handleChange}
                           required
                        />
                     </label>
                  </p>
                  <p>
                     <label>
                        Last Name:{' '}
                        <input
                           type='text'
                           name='lastName'
                           value={dispatchForm.lastName}
                           onChange={handleChange}
                           required
                        />
                     </label>
                  </p>
               </div>
               <div className='form-group'>
                  <p>
                     <label>
                        Email:{' '}
                        <input
                           type='email'
                           name='email'
                           value={dispatchForm.email}
                           onChange={handleChange}
                           required
                        />
                     </label>
                  </p>
                  <p>
                     <label>
                        Phone:{' '}
                        <input
                           type='tel'
                           name='phone'
                           value={dispatchForm.phone}
                           onChange={handleChange}
                           // required
                        />
                     </label>
                  </p>
               </div>
               <p>
                  <label>
                     MC number:{' '}
                     <input
                        type='text'
                        name='MCNumber'
                        value={dispatchForm.MCNumber}
                        onChange={handleChange}
                        required
                     />
                  </label>
               </p>
               <p>
                  <label>
                     Type of Truck:{' '}
                     <textarea
                        name='truckType'
                        value={dispatchForm.truckType}
                        onChange={handleChange}
                        cols='20'
                        rows='3'
                        required
                     />
                  </label>
               </p>
               <p>
                  <label>
                     Location Details:{' '}
                     <textarea
                        name='locationDetails'
                        value={dispatchForm.locationDetails}
                        onChange={handleChange}
                        cols='20'
                        rows='3'
                        required
                     />
                  </label>
               </p>

               <p>
                  <label>
                     Date Load needed:{' '}
                     <input
                        type='date'
                        name='dateLoad'
                        value={dispatchForm.dateLoad}
                        onChange={handleChange}
                        required
                     />
                  </label>
               </p>
               <p>
                  <button type='submit' className='dark-btn'>
                     Send
                  </button>
               </p>
            </form>
         </div>
      </DispatchFormWrap>
   );
};

export default DispatchForm;

const DispatchFormWrap = styled.section`
   background-color: ${(props) => props.theme.colors.gray1};

   .container {
      max-width: 800px !important;
   }

   form {
      label {
         display: flex;
         flex-direction: column;
      }

      input,
      textarea {
         font-family: ${(props) => props.theme.fonts.secondary};
         padding: 0.75rem 0.5rem;
         font-size: 1rem;
         border: 1px solid ${(props) => props.theme.colors.gray5};
         border-radius: 5px;
         transition: ${(props) => props.theme.misc.transitionEase};

         &:hover,
         &:focus {
            background-color: ${(props) => props.theme.colors.gray1};
         }
      }
   }

   @media screen and (min-width: ${breakpoints.desktop}px) {
      .form-group {
         display: grid;
         grid-template-columns: repeat(2, 1fr);
         gap: 1rem;
      }
   }
`;
