import React from 'react';
import { Link } from 'gatsby';
import Dropdown from './Dropdown';
import styled from 'styled-components';
import { IoMdArrowDropdown, IoMdArrowDropright } from 'react-icons/io';
import { breakpoints } from '../../styles/globalStyle';
import { useDispatch } from 'react-redux';
import {
   closeMenu,
   openDropdown,
   toggleDropdown,
} from '../../redux/features/globalSlice';

const MenuItem = ({ link }) => {
   const dispatch = useDispatch();
   const { id, linkTitle, linkAddress, dropdown } = link;

   return (
      <LinkWrap>
         <Link
            to={linkAddress}
            activeClassName='active'
            onClick={() => dispatch(closeMenu())}
         >
            {linkTitle}
         </Link>
         {dropdown && (
            <span className='dropdown-icon'>
               <IoMdArrowDropright
                  className='mobile icon'
                  onClick={() => dispatch(openDropdown(id))}
               />
               <IoMdArrowDropdown
                  className='desktop icon'
                  onClick={() => dispatch(toggleDropdown(id))}
               />
            </span>
         )}
         {dropdown && <Dropdown dropdown={dropdown} id={id} />}
      </LinkWrap>
   );
};

export default MenuItem;

const LinkWrap = styled.li`
   position: relative;

   & > a {
      display: block;
      padding: 1rem 2rem;
      border-bottom: ${(props) => props.theme.colors.gray8} 1px solid;
      letter-spacing: 2px;
      color: ${(props) => props.theme.colors.white};
      width: 100%;
      margin-bottom: 0;

      &:hover {
         background-color: ${(props) => props.theme.colors.gray7};
         color: ${(props) => props.theme.colors.gray2};
         transition: ${(props) => props.theme.misc.transitionEase};
      }
   }

   .dropdown-icon {
      .desktop {
         display: none;
      }

      .icon {
         position: absolute;
         right: 2rem;
         top: 50%;
         transform: translateY(-50%);
         font-size: 2rem;
         cursor: pointer;
         color: ${(props) => props.theme.colors.gray1};
      }
   }

   @media screen and (min-width: ${breakpoints.desktop}px) {
      display: flex;
      align-items: flex-end;

      & > a {
         display: unset;
         padding: unset;
         border-bottom: unset;
         letter-spacing: 2px;
         width: unset;
         font-weight: 400;

         &:hover {
            background-color: unset;
            color: ${(props) => props.theme.colors.gray5};
            transition: ${(props) => props.theme.misc.transitionEase};
         }
      }

      .dropdown-icon {
         .mobile {
            display: none;
         }

         .desktop {
            display: inline;
         }

         .icon {
            position: relative;
            transform: unset;
            top: unset;
            right: unset;
            margin-bottom: -0.25rem;
            transition: filter 300ms;

            &:hover {
               filter: brightness(1.5);
            }
         }
      }
   }
`;
