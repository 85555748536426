import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Copyright, FooterContent } from '../index';

const Footer = () => {
   const data = useStaticQuery(graphql`
      query FooterQuery {
         site {
            siteMetadata {
               author
               title
            }
         }
      }
   `);
   return (
      <footer>
         <FooterContent />
         <Copyright title={data.site.siteMetadata.title} />
      </footer>
   );
};

export default Footer;
