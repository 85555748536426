import { createSlice } from '@reduxjs/toolkit';

const initialState = {
   firstName: '',
   lastName: '',
   email: '',
   phone: '',
   MCNumber: '',
   truckType: '',
   locationDetails: '',
   dateLoad: '',
};

export const dispatchFormSlice = createSlice({
   name: 'dispatch form',
   initialState,
   reducers: {
      editForm: (state, action) => {
         if (action.payload.name === 'firstName') {
            state.firstName = action.payload.value;
         }

         if (action.payload.name === 'lastName') {
            state.lastName = action.payload.value;
         }

         if (action.payload.name === 'email') {
            state.email = action.payload.value;
         }

         if (action.payload.name === 'phone') {
            state.phone = action.payload.value;
         }

         if (action.payload.name === 'MCNumber') {
            state.MCNumber = action.payload.value;
         }

         if (action.payload.name === 'truckType') {
            state.truckType = action.payload.value;
         }

         if (action.payload.name === 'locationDetails') {
            state.locationDetails = action.payload.value;
         }
         if (action.payload.name === 'dateLoad') {
            state.dateLoad = action.payload.value;
         }
      },
      resetForm: (state) => {
         state.firstName = '';
         state.lastName = '';
         state.email = '';
         state.phone = '';
         state.MCNumber = '';
         state.typeOfTruck = '';
         state.locationDetails = '';
         state.dateLoad = '';
      },
   },
});

// Action creators are generated for each case reducer function
export const { editForm, resetForm } = dispatchFormSlice.actions;

export const selectDispatchForm = (state) => state.dispatchForm;

export default dispatchFormSlice.reducer;
