import React from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../styles/globalStyle';
import { BsArrowRightSquareFill } from 'react-icons/bs';
import { StructuredText } from 'react-datocms';

const PageSubtitle = ({
   list = [],
   subtitle,
   headingOne,
   description,
   center,
   structured,
}) => {
   return (
      <PageSubtitleWrap>
         <PageSubtitleInner className={center ? 'center' : 'container'}>
            {headingOne ? <h1>{subtitle}</h1> : <h2>{subtitle}</h2>}
            {list.length > 0 && (
               <>
                  <p>We service: </p>
                  <Lists>
                     {list.map((item) => {
                        return (
                           <li key={item.id}>
                              <BsArrowRightSquareFill className='icon' />
                              <span>{item.listItem}</span>
                           </li>
                        );
                     })}
                  </Lists>
               </>
            )}

            {description && structured ? (
               <StructuredText data={description} />
            ) : (
               description && <p>{description}</p>
            )}
         </PageSubtitleInner>
      </PageSubtitleWrap>
   );
};

export default PageSubtitle;

const PageSubtitleWrap = styled.section``;

const PageSubtitleInner = styled.div`
   max-width: 800px !important;
   margin: auto;

   h1 {
      font-size: 1.75rem;
   }

   h2 {
      font-size: 1.25rem;
   }

   &.center {
      text-align: center;
   }

   @media screen and (min-width: ${breakpoints.desktop}px) {
      h1 {
         font-size: 3rem;
      }

      h2 {
         font-size: 1.825rem;
      }

      p {
         font-size: 1.1rem;
         line-height: 40px;
      }
   }
`;

const Lists = styled.ul`
   li {
      margin: 0.5rem 0;
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: center;
      gap: 0.5rem;

      @media screen and (min-width: ${breakpoints.desktop}px) {
         font-size: 1.1rem;
      }
   }
`;
