import { createSlice } from '@reduxjs/toolkit';

const initialState = {
   service: '',
   name: '',
   email: '',
   phone: '',
   company: '',
   message: '',
   success: false,
};

export const contactFormSlice = createSlice({
   name: 'contact form',
   initialState,
   reducers: {
      editContactForm: (state, action) => {
         if (action.payload.name === 'service') {
            state.service = action.payload.value;
         }

         if (action.payload.name === 'name') {
            state.name = action.payload.value;
         }

         if (action.payload.name === 'email') {
            state.email = action.payload.value;
         }

         if (action.payload.name === 'phone') {
            state.phone = action.payload.value;
         }

         if (action.payload.name === 'company') {
            state.company = action.payload.value;
         }

         if (action.payload.name === 'message') {
            state.message = action.payload.value;
         }
      },
      formSuccess: (state) => {
         state.success = true;
      },

      resetContactForm: (state) => {
         state.service = '';
         state.name = '';
         state.email = '';
         state.phone = '';
         state.company = '';
         state.message = '';
         state.success = false;
      },
   },
});

// Action creators are generated for each case reducer function
export const { editContactForm, resetContactForm, formSuccess } =
   contactFormSlice.actions;

export const selectContactForm = (state) => state.contactForm;

export default contactFormSlice.reducer;
