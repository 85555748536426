import { configureStore } from '@reduxjs/toolkit';
import globalReducer from './features/globalSlice';
import dispatchFormReducer from './features/dispatchFormSlice';
import contactFormReducer from './features/contactFormSlice';
import getQuoteReducer from './features/getQuoteSlice';

export const store = configureStore({
   reducer: {
      global: globalReducer,
      dispatchForm: dispatchFormReducer,
      contactForm: contactFormReducer,
      getQuote: getQuoteReducer,
   },
});
