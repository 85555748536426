import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { breakpoints } from '../../styles/globalStyle';

const CallToAction = ({
   ctaTitle = 'Get in Touch',
   btnText = 'Inquire',
   description,
   address,
   center,
   dark,
   lightBG,
   linkAddress,
}) => {
   return (
      <CallToActionWrap className={lightBG && 'light-bg'}>
         <CallToActionInner className={center ? 'center' : 'container'}>
            <h3>{ctaTitle}</h3>
            {description && <p>{description}</p>}
            {linkAddress && (
               <a href={linkAddress} target='_blank' rel='noreferrer'>
                  <button className='dark-btn'>{btnText}</button>
               </a>
            )}
            {address && (
               <Link to={address}>
                  <button className={dark ? 'btn dark' : 'btn'}>
                     {btnText}
                  </button>
               </Link>
            )}
         </CallToActionInner>
      </CallToActionWrap>
   );
};

export default CallToAction;

const CallToActionWrap = styled.section`
   background-color: ${(props) => props.theme.colors.gray1};

   &.light-bg {
      background-color: ${(props) => props.theme.colors.white};
   }
`;

const CallToActionInner = styled.div`
   /* padding: 0 5%; */
   max-width: 800px !important;
   margin: auto;

   h3 {
      text-transform: capitalize;
   }

   &.center {
      text-align: center;
   }

   .btn {
      &.dark {
         background-color: ${(props) => props.theme.colors.black};
         color: ${(props) => props.theme.colors.white};
      }
   }

   @media screen and (min-width: ${breakpoints.desktop}px) {
      h3 {
         font-size: 1.825rem;
      }

      p {
         padding: 0.5rem 0;
      }
   }
`;
