import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../styles/globalStyle';

const Team = () => {
   return (
      <TeamWrap>
         <TeamInner className='container'>
            <article>
               <StaticImage
                  src='../../images/placeholder-image.png'
                  alt='Amber McWeay'
               />
               <h3>Amber McWeay</h3>
               <p>Owner / CEO</p>
            </article>
            <article>
               <StaticImage
                  src='../../images/placeholder-image.png'
                  alt='Sabrina McWeay'
               />
               <h3>Sabrina McWeay</h3>
               <p>Logistics Director</p>
            </article>
         </TeamInner>
      </TeamWrap>
   );
};

export default Team;

const TeamWrap = styled.section`
   padding-top: 0;
`;

const TeamInner = styled.div`
   display: grid;
   gap: 2rem;

   article {
      text-align: center;

      .gatsby-image-wrapper {
         margin-bottom: 1.25rem;
      }
   }

   @media screen and (min-width: ${breakpoints.desktop}px) {
      grid-template-columns: repeat(2, 1fr);
      max-width: 700px !important;
   }
`;
