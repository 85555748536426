import { v4 as uuidv4 } from 'uuid';

export const menuLinks = [
   {
      id: uuidv4(),
      linkTitle: 'Home',
      linkAddress: '/',
   },
   {
      id: uuidv4(),
      linkTitle: 'Shippers',
      linkAddress: '/services/shipper-services',
   },
   {
      id: uuidv4(),
      linkTitle: 'Carriers',
      linkAddress: '/carriers',
   },
   {
      id: uuidv4(),
      linkTitle: 'Dispatch',
      linkAddress: '/services/dispatch-services',
   },

   {
      id: uuidv4(),
      linkTitle: 'About Us',
      linkAddress: '/#about',
   },
   {
      id: uuidv4(),
      linkTitle: 'Contact Us',
      linkAddress: '/contact',
   },
];

export const servicesLinks = [
   {
      id: uuidv4(),
      title: 'Freight Services',
      image: '../../images/bg-13.jpg',
      address: '/services/freight-services',
   },
   {
      id: uuidv4(),
      image: '../images/bg-14.jpg',
      title: 'Dispatch Services',
      address: '/services/dispatch-services',
   },
];
