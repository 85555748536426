import React from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../styles/globalStyle';
import bgImage from '../../images/center-img.jpg';

const ImageSection = ({ img }) => {
   return <ImageSectionWrap></ImageSectionWrap>;
};

export default ImageSection;

const ImageSectionWrap = styled.section`
   display: none;
   padding: 0;
   position: relative;
   height: 250px;
   background-image: url(${bgImage});
   background-size: cover;
   background-repeat: no-repeat;
   background-position: center;
   background-attachment: fixed;
   z-index: 8;

   .slash {
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%, -30%);
   }

   @media screen and (min-width: ${breakpoints.desktop}px) {
      display: block;
      height: 450px;
   }
`;
