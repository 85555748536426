import React from 'react';
import styled from 'styled-components';
import { breakpoints, dimensions } from '../../styles/globalStyle';
import { HiOutlineMenuAlt3, HiX } from 'react-icons/hi';
import { menuLinks } from '../../data/links';
import MenuItem from './MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { selectMenuOpen, toggleMenu } from '../../redux/features/globalSlice';

const Header = () => {
   const dispatch = useDispatch();
   const menuOpen = useSelector(selectMenuOpen);

   return (
      <HeaderWrap className={menuOpen ? 'opened' : null}>
         <Navigation className='container'>
            <div>
               {/* <Link
                  to='/'
                  onClick={() => dispatch(closeMenu())}
                  className={noLogo ? 'no-logo logo' : 'logo'}
               >
                  <img src={logo} alt='barcode-logo' />
               </Link> */}
            </div>

            <MenuLinks className={menuOpen ? 'opened' : null}>
               {menuLinks.map((link) => {
                  return <MenuItem key={link.id} link={link} />;
               })}
            </MenuLinks>
            <Hamburger onClick={() => dispatch(toggleMenu())}>
               {menuOpen ? <HiX /> : <HiOutlineMenuAlt3 />}
            </Hamburger>
         </Navigation>
      </HeaderWrap>
   );
};

export default Header;

const HeaderWrap = styled.header`
   position: relative;

   background: transparent;
   height: ${dimensions.headerHeight};
   z-index: 10;
   margin-bottom: -${dimensions.headerHeight};
   transition: ${(props) => props.theme.misc.transitionEase};

   &.opened {
      position: sticky;
      top: 0;
      background: ${(props) => props.theme.colors.black};
   }

   @media screen and (min-width: ${breakpoints.desktop}px) {
      position: relative;
      background: transparent;
   }
`;

const Navigation = styled.nav`
   display: flex;
   align-items: center;
   justify-content: space-between;
   height: 100%;

   .no-logo {
      display: none;
      background: red;
   }

   .logo {
      margin-bottom: 0;
      line-height: 0;

      img {
         /* width: 100%; */
         max-width: 175px;
      }
   }
`;

const MenuLinks = styled.ul`
   position: fixed;
   background-color: ${(props) => props.theme.colors.black};
   border-top: ${(props) => props.theme.colors.gray7} 1px solid;
   top: ${dimensions.headerHeight};
   left: 0;
   height: 100%;
   width: 100%;
   display: flex;
   flex-direction: column;
   font-weight: bold;
   z-index: 10;
   transform: translateX(100%);
   transition: ${(props) => props.theme.misc.transitionEase};

   &.opened {
      transform: translateX(0);
      transition: ${(props) => props.theme.misc.transitionEase};
   }

   @media screen and (min-width: ${breakpoints.desktop}px) {
      position: relative;
      top: unset;
      left: unset;
      height: unset;
      width: unset;
      flex-direction: row;
      gap: 3rem;
      z-index: unset;
      transform: unset;
      border-top: unset;
      background-color: transparent;
   }
`;

const Hamburger = styled.button`
   background: none;
   font-size: 2rem;
   outline: none;
   border: none;
   cursor: pointer;

   @media screen and (min-width: ${breakpoints.desktop}px) {
      display: none;
   }
`;
